import { Subscribe } from 'unstated';
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { useQuery } from 'react-query';

import './index.css';
import { getUser } from 'actions';
import enhancer from './enhancer';
import Notification from 'components/Notification';
import NotificationsState from 'global/Notifications';

const Authenticated = ({ children }) => {
  const { data } = useQuery('getAuthUser', () => getUser(), {
    onSuccess: data => {
      localStorage.setItem('fmalebureau-user', JSON.stringify({ ...data }));
    },
    initialData: () => {
      const localUser = localStorage.getItem('fmalebureau-user');

      if (localUser) {
        return JSON.parse(localUser);
      }

      return undefined;
    },
  });

  return data ? (
    <Fragment>
      <header>
        <nav className="navbar is-white">
          <div className="navbar-brand">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              role="button"
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>
          <div className="navbar-menu">
            <div className="navbar-start">
              <NavLink activeClassName="is-active" className="navbar-item" exact to="/">
                PDF
              </NavLink>
              {data.scopes.find(scope => scope.reference === 'crud-artist') && (
                <NavLink activeClassName="is-active" className="navbar-item" to="/artists">
                  Artistes
                </NavLink>
              )}

              {data.scopes.find(scope => scope.reference === 'crud-landing') && (
                <NavLink activeClassName="is-active" className="navbar-item" to="/landings">
                  Landings
                </NavLink>
              )}
              {data.scopes.find(scope => scope.reference === 'crud-post') && (
                <NavLink activeClassName="is-active" className="navbar-item" to="/pages">
                  Pages
                </NavLink>
              )}
              {data.scopes.find(scope => scope.reference === 'crud-post') && (
                <NavLink activeClassName="is-active" className="navbar-item" to="/posts">
                  Posts
                </NavLink>
              )}
              {data.scopes.find(scope => scope.reference === 'crud-produce') && (
                <NavLink activeClassName="is-active" className="navbar-item" to="/produces">
                  Produce
                </NavLink>
              )}
              {data.scopes.find(scope => scope.reference === 'crud-project') && (
                <NavLink activeClassName="is-active" className="navbar-item" to="/projects">
                  Projets
                </NavLink>
              )}

              <div className="navbar-item has-dropdown is-hoverable">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="navbar-link">Plus</a>
                <div className="navbar-dropdown">
                  {data.scopes.find(scope => scope.reference === 'crud-category') && (
                    <NavLink activeClassName="is-active" className="navbar-item" to="/categories">
                      Catégories Projet
                    </NavLink>
                  )}
                  {data.scopes.find(scope => scope.reference === 'crud-client') && (
                    <NavLink activeClassName="is-active" className="navbar-item" to="/clients">
                      Clients
                    </NavLink>
                  )}
                  {data.scopes.find(scope => scope.reference === 'crud-domain') && (
                    <NavLink activeClassName="is-active" className="navbar-item" to="/domains">
                      Domaines Artistes
                    </NavLink>
                  )}
                  {data.scopes.find(scope => scope.reference === 'crud-user') && (
                    <NavLink activeClassName="is-active" className="navbar-item" to="/users">
                      Utilisateurs
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <main className="Main">{children}</main>
      <Subscribe to={[NotificationsState]}>
        {notifications => (
          <section className="NotificationsContainer">
            {notifications.state.notifications
              .map((notification, index) => (
                <Notification {...notification} key={index} remove={notifications.remove} />
              ))
              .reverse()}
          </section>
        )}
      </Subscribe>
    </Fragment>
  ) : null;
};

export default enhancer(Authenticated);
