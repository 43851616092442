import { request } from 'utils';

export const getProducesAll = () =>
  request({
    method: 'GET',
    url: `/api/produces/all`,
  });

export const getProduces = ({ page = 1, search = '' }) =>
  request({
    method: 'GET',
    url: `/api/produces`,
    params: {
      page,
      search,
    },
  });

export const getProduce = id =>
  request({
    method: 'GET',
    url: `/api/produces/${id}`,
  });

export const postProduce = data => request({ method: 'POST', url: '/api/produces', data });

export const putProduce = data => request({ method: 'PUT', url: `/api/produces/${data.id}`, data });

export const putProducesOrder = data =>
  request({ method: 'PUT', url: `/api/produces/order`, data });

export const archiveProduce = id => request({ method: 'DELETE', url: `/api/produces/${id}` });

// export const destroyProduce = id => request({ method: 'DELETE', url: `/api/produces/${id}/force` });

export const getArchiveProduces = ({ page = 1, search = '' }) =>
  request({
    method: 'GET',
    url: `/api/produces/archive`,
    params: {
      page,
      search,
    },
  });

export const restoreProduce = id => request({ method: 'PUT', url: `/api/produces/${id}/restore` });
