import React, { Fragment } from 'react';

import enhancer from './enhancer';
import logo from '../../images/fmalebureau-logo.svg';

import './index.css';

const Unauthenticated = ({ children }) => (
  <Fragment>
    <div className="Unauthenticated">
      <div className="UnauthenticatedContent">
        <header className="UnauthenticatedHeader">
          <img src={logo} alt="Florence Moll & Associe Le Bureau Admin Login" />
        </header>
        {children}
      </div>
    </div>
  </Fragment>
);

export default enhancer(Unauthenticated);
