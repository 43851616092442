import React from 'react';
import { Route } from 'react-router-dom';

import Authenticated from 'components/Authenticated';
import Unauthenticated from 'components/Unauthenticated';

export const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Authenticated>
        <Component {...props} />
      </Authenticated>
    )}
  />
);

export const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Unauthenticated>
        <Component {...props} />
      </Unauthenticated>
    )}
  />
);
