import React from 'react';
import Loadable from 'react-loadable';

import { AuthenticatedRoute } from 'components/Routing';

const Cities = Loadable({
  loader: () => import('./List'),
  loading: () => <div>Loading</div>,
});

const CityCreate = Loadable({
  loader: () => import('./Create'),
  loading: () => <div>Loading</div>,
});

const CityEdit = Loadable({
  loader: () => import('./Edit'),
  loading: () => <div>Loading</div>,
});

const CitiesArchive = Loadable({
  loader: () => import('./Archive'),
  loading: () => <div>Loading</div>,
});

const CitiesRoutes = [
  <AuthenticatedRoute key="1" exact path="/cities" component={Cities} />,
  <AuthenticatedRoute
    key="2"
    excat
    path="/cities/create"
    component={CityCreate}
  />,
  <AuthenticatedRoute
    key="3"
    excat
    path="/cities/:id/edit"
    component={CityEdit}
  />,
  <AuthenticatedRoute
    key="4"
    excat
    path="/cities/archive"
    component={CitiesArchive}
  />,
];

export default CitiesRoutes;
