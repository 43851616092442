import React from 'react';
import Loadable from 'react-loadable';

import { AuthenticatedRoute } from 'components/Routing';

const Posts = Loadable({
  loader: () => import('./List'),
  loading: () => <div>Loading</div>,
});

const PostCreate = Loadable({
  loader: () => import('./Create'),
  loading: () => <div>Loading</div>,
});

const PostEdit = Loadable({
  loader: () => import('./Edit'),
  loading: () => <div>Loading</div>,
});

const PostsArchive = Loadable({
  loader: () => import('./Archive'),
  loading: () => <div>Loading</div>,
});

const PostsOrder = Loadable({
  loader: () => import('./Order'),
  loading: () => <div>Loading</div>,
});

const PostsRoutes = [
  <AuthenticatedRoute key="1" exact path="/posts" component={Posts} />,
  <AuthenticatedRoute
    key="2"
    excat
    path="/posts/create"
    component={PostCreate}
  />,
  <AuthenticatedRoute
    key="3"
    excat
    path="/posts/:id/edit"
    component={PostEdit}
  />,
  <AuthenticatedRoute
    key="4"
    excat
    path="/posts/archive"
    component={PostsArchive}
  />,
  <AuthenticatedRoute
    key="5"
    excat
    path="/posts/order"
    component={PostsOrder}
  />,
];

export default PostsRoutes;
