import { v4 as uuidv4 } from 'uuid';
import { Container } from 'unstated';

type Notification = {
  id: string,
  message: string,
  type: string,
};

type NotificationState = {
  notifications: [Notification],
};

class NotificationContainer extends Container<NotificationState> {
  state = {
    notifications: [],
  };

  add = newNotification => {
    const notificationToAdd = {
      ...newNotification,
      id: uuidv4(),
    };

    this.setState(prevState => ({
      notifications: [...prevState.notifications, notificationToAdd],
    }));
  };

  remove = id => {
    this.setState(prevState => ({
      notifications: prevState.notifications.filter(notif => notif.id !== id),
    }));
  };
}

export default NotificationContainer;
