import { withRouter } from 'react-router';
import { compose, lifecycle } from 'recompose';

import { retrieveSession } from 'utils/session';

const lifecycleHooks = {
  componentDidMount() {
    const { history: { push } } = this.props;
    const session = retrieveSession();

    if (session) {
      push('/');
      return;
    }
  },
  componentWillReceiveProps(nextProps) {
    const { history: { push } } = this.props;
    const session = retrieveSession();

    if (session) {
      push('/');
      return;
    }
  },
};

const enhancer = compose(withRouter, lifecycle(lifecycleHooks));

export default enhancer;
