import React from 'react';
import Loadable from 'react-loadable';

import { AuthenticatedRoute } from 'components/Routing';

const Domains = Loadable({
  loader: () => import('./List'),
  loading: () => <div>Loading</div>,
});

const DomainCreate = Loadable({
  loader: () => import('./Create'),
  loading: () => <div>Loading</div>,
});

const DomainEdit = Loadable({
  loader: () => import('./Edit'),
  loading: () => <div>Loading</div>,
});

const DomainsArchive = Loadable({
  loader: () => import('./Archive'),
  loading: () => <div>Loading</div>,
});

const DomainsOrder = Loadable({
  loader: () => import('./Order'),
  loading: () => <div>Loading</div>,
});

const DomainsRoutes = [
  <AuthenticatedRoute key="1" exact path="/domains" component={Domains} />,
  <AuthenticatedRoute
    key="2"
    excat
    path="/domains/create"
    component={DomainCreate}
  />,
  <AuthenticatedRoute
    key="3"
    excat
    path="/domains/:id/edit"
    component={DomainEdit}
  />,
  <AuthenticatedRoute
    key="4"
    excat
    path="/domains/archive"
    component={DomainsArchive}
  />,
  <AuthenticatedRoute
    key="5"
    excat
    path="/domains/order"
    component={DomainsOrder}
  />,
];

export default DomainsRoutes;
