import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import './index.css';
import App from './App';
import config from 'utils/config';
import { unregister } from './registerServiceWorker';

Sentry.init({
  dsn: config.sentry,
  environment: config.env,
  release: `v3@0.5.0`,
});

ReactDOM.render(<App />, document.getElementById('root'));
unregister();
