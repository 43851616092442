import { request } from 'utils';

export const getLandings = () =>
  request({
    method: 'GET',
    url: `/api/landings`,
  });

export const getLanding = (key, id) =>
  request({
    method: 'GET',
    url: `/api/landings/${id}`,
  });

export const postLanding = data => request({ method: 'POST', url: '/api/landings', data });

export const putLanding = data => request({ method: 'PUT', url: `/api/landings/${data.id}`, data });

export const deleteLanding = id => request({ method: 'DELETE', url: `/api/landings/${id}` });
