const AUTH_KEY = 'fmalebureau';

export const retrieveSession = () => JSON.parse(localStorage.getItem('fmalebureau'));

export const setSession = session => {
  localStorage.setItem(AUTH_KEY, JSON.stringify(session));
};

export const deleteSession = () => localStorage.removeItem('fmalebureau');

export const logout = ({ error, didUnmount }) => {
  if (error && !didUnmount) {
    deleteSession();
    window.location.reload();
  }
};

export const getSession = () => JSON.parse(localStorage.getItem(AUTH_KEY));

export const clearSession = () => localStorage.removeItem(AUTH_KEY);
