import { request } from 'utils';

export const getClients = key =>
  request({
    method: 'GET',
    url: `/api/clients`,
  });

export const getClientsAll = key =>
  request({
    method: 'GET',
    url: '/api/clients/all',
  });

export const getClient = (key, id) =>
  request({
    method: 'GET',
    url: `/api/clients/${id}`,
  });

export const postClient = data => request({ method: 'POST', url: '/api/clients', data });

export const putClient = data => request({ method: 'PUT', url: `/api/clients/${data.id}`, data });

export const archiveClient = id => request({ method: 'DELETE', url: `/api/clients/${id}` });

export const getArchiveClients = (key, { page = 1, search = '' }) =>
  request({ method: 'GET', url: `/api/clients/archive?page=${page}&search=${search}` });

export const restoreClient = id => request({ method: 'PUT', url: `/api/clients/${id}/restore` });
