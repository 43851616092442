import { request } from 'utils';

export const getPages = () =>
  request({
    method: 'GET',
    url: `/api/pages`,
  });

export const getPage = id =>
  request({
    method: 'GET',
    url: `/api/pages/${id}`,
  });

// export const postArtist = data => request({ method: 'POST', url: '/api/artists', data });

export const putPage = data => request({ method: 'PUT', url: `/api/pages/${data.id}`, data });
