import axios from 'axios';
import { history } from './history';

import config from './config';
import { getSession, clearSession } from './session';

export function parseToJson(response) {
  return response.json();
}

export function checkStatus(response) {
  if (response.ok) {
    return response;
  }

  throw response;
}

export const client = axios.create({
  baseURL: config.api,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const request = query => {
  const session = getSession();

  return client
    .request({
      headers: {
        Authorization: session ? `Bearer ${session.access_token}` : '',
      },
      ...query,
    })
    .then(res => res.data)
    .catch(err => {
      if (err.response.status === 401) {
        clearSession();
        history.push('/login');
      }
      throw err;
    });
};
