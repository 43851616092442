import React from 'react';
import { Switch } from 'react-router';
import { Provider as UnstatedProvider } from 'unstated';
import { Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { history } from 'utils';

import ArtistsRoutes from 'pages/Artists/routes';
import AuthRoutes from 'pages/Auth/routes';
import CategoriesRoutes from 'pages/Categories/routes';
import CitiesRoutes from 'pages/Cities/routes';
import ClientsRoutes from 'pages/Clients/routes';
import DashboardRoute from 'pages/Dashboard/routes';
import DomainsRoutes from 'pages/Domains/routes';
import LandingsRoutes from 'pages/Landings/routes';
import PostsRoutes from 'pages/Posts/routes';
import ProducesRoutes from 'pages/Produces/routes';
import ProjectsRoutes from 'pages/Projects/routes';
import UsersRoutes from 'pages/Users/routes';
import PagesRoutes from 'pages/Pages/routes';

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <UnstatedProvider>
      <Router history={history}>
        <Switch>
          {ArtistsRoutes}
          {AuthRoutes}
          {CategoriesRoutes}
          {CitiesRoutes}
          {ClientsRoutes}
          {DashboardRoute}
          {DomainsRoutes}
          {LandingsRoutes}
          {PagesRoutes}
          {PostsRoutes}
          {ProducesRoutes}
          {ProjectsRoutes}
          {UsersRoutes}
        </Switch>
      </Router>
    </UnstatedProvider>
    {/* <ReactQueryDevtools /> */}
  </QueryClientProvider>
);

export default App;
