import React from 'react';
import Loadable from 'react-loadable';

import { UnauthenticatedRoute } from 'components/Routing';

const Login = Loadable({
  loader: () => import('./Login'),
  loading: () => <div>Loading</div>,
});

const AuthRoutes = [
  <UnauthenticatedRoute key="1" exact path="/login" component={Login} />,
];

export default AuthRoutes;
