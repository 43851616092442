import { request } from 'utils';

export const getDomains = () =>
  request({
    method: 'GET',
    url: `/api/domains`,
  });

export const getDomain = id =>
  request({
    method: 'GET',
    url: `/api/domains/${id}`,
  });

export const postDomain = data => request({ method: 'POST', url: '/api/domains', data });

export const putDomain = data => request({ method: 'PUT', url: `/api/domains/${data.id}`, data });

export const archiveDomain = id => request({ method: 'DELETE', url: `/api/domains/${id}` });

export const getArchivedomains = ({ page = 1, search = '' }) =>
  request({ method: 'GET', url: `/api/domains/archive?page=${page}&search=${search}` });

export const restoreDomain = id => request({ method: 'PUT', url: `/api/domains/${id}/restore` });
