import React from 'react';
import Loadable from 'react-loadable';

import { AuthenticatedRoute } from 'components/Routing';

const Dashboard = Loadable({
  loader: () => import('./index'),
  loading: () => <div>Loading</div>,
});

const DashboardRoutes = [
  <AuthenticatedRoute key="1" exact path="/" component={Dashboard} />,
];

export default DashboardRoutes;
