import React from 'react';
import Loadable from 'react-loadable';

import { AuthenticatedRoute } from 'components/Routing';

const Produces = Loadable({
  loader: () => import('./List'),
  loading: () => <div>Loading</div>,
});

const ProduceCreate = Loadable({
  loader: () => import('./Create'),
  loading: () => <div>Loading</div>,
});

const ProduceEdit = Loadable({
  loader: () => import('./Edit'),
  loading: () => <div>Loading</div>,
});

const ProducesArchive = Loadable({
  loader: () => import('./Archive'),
  loading: () => <div>Loading</div>,
});

const ProducesOrder = Loadable({
  loader: () => import('./Order'),
  loading: () => <div>Loading</div>,
});

const ProducesRoutes = [
  <AuthenticatedRoute key="1" exact path="/produces" component={Produces} />,
  <AuthenticatedRoute key="2" excat path="/produces/create" component={ProduceCreate} />,
  <AuthenticatedRoute key="3" excat path="/produces/:id/edit" component={ProduceEdit} />,
  <AuthenticatedRoute key="4" excat path="/produces/archive" component={ProducesArchive} />,
  <AuthenticatedRoute key="5" excat path="/produces/order" component={ProducesOrder} />,
];

export default ProducesRoutes;
