import { request } from 'utils';

export const getProjectsAll = () =>
  request({
    method: 'GET',
    url: `/api/projects/all`,
  });

export const getProjects = ({ page = 1, search = '' }) =>
  request({
    method: 'GET',
    url: `/api/projects`,
    params: {
      page,
      search,
    },
  });

export const getProject = id =>
  request({
    method: 'GET',
    url: `/api/projects/${id}`,
  });

export const postProject = data => request({ method: 'POST', url: '/api/projects', data });

export const putProject = data => request({ method: 'PUT', url: `/api/projects/${data.id}`, data });

export const archiveProject = id => request({ method: 'DELETE', url: `/api/projects/${id}` });

export const destroyProject = id => request({ method: 'DELETE', url: `/api/projects/${id}/force` });

export const getArchiveProjects = ({ page = 1, search = '' }) =>
  request({
    method: 'GET',
    url: `/api/projects/archive`,
    params: {
      page,
      search,
    },
  });

export const restoreProject = id => request({ method: 'PUT', url: `/api/projects/${id}/restore` });
