import React from 'react';
import { compose, lifecycle } from 'recompose';

const enhancer = compose(
  lifecycle({
    componentDidMount() {
      setTimeout(() => {
        this.props.remove(this.props.id);
      }, 5000);
    },
  }),
);

const Notification = ({ id, type, message, remove }) => (
  <div className={`notification is-${type}`}>
    <button className="delete" onClick={() => remove(id)} />
    {message}
  </div>
);

export default enhancer(Notification);
