import { request } from 'utils';

export const getArtists = () =>
  request({
    method: 'GET',
    url: `/api/artists`,
  });

export const getArtist = id =>
  request({
    method: 'GET',
    url: `/api/artists/${id}`,
  });

export const postArtist = data => request({ method: 'POST', url: '/api/artists', data });

export const putArtist = data => request({ method: 'PUT', url: `/api/artists/${data.id}`, data });

export const archiveArtist = id => request({ method: 'DELETE', url: `/api/artists/${id}` });

export const getArchiveArtists = ({ page = 1, search = '' }) =>
  request({ method: 'GET', url: `/api/artists/archive?page=${page}&search=${search}` });

export const restoreArtist = id => request({ method: 'PUT', url: `/api/artists/${id}/restore` });

export const getArtistProjects = id =>
  request({
    method: 'GET',
    url: `/api/artists/${id}/projects`,
  });
