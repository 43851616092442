import { request } from 'utils';

export const getCategories = () =>
  request({
    method: 'GET',
    url: `/api/categories`,
  });

export const getCategory = (key, id) =>
  request({
    method: 'GET',
    url: `/api/categories/${id}`,
  });

export const postCategory = data => request({ method: 'POST', url: '/api/categories', data });

export const putCategory = data =>
  request({ method: 'PUT', url: `/api/categories/${data.id}`, data });

export const archiveCategory = id => request({ method: 'DELETE', url: `/api/categories/${id}` });

export const getArchiveCategories = (key, { page = 1, search = '' }) =>
  request({ method: 'GET', url: `/api/categories/archive?page=${page}&search=${search}` });

export const restoreCategory = id =>
  request({ method: 'PUT', url: `/api/categories/${id}/restore` });
