import { request } from 'utils';

export const getPostsAll = () =>
  request({
    method: 'GET',
    url: `/api/posts/all`,
  });

export const getPosts = ({ page = 1, search = '' }) =>
  request({
    method: 'GET',
    url: `/api/posts?page=${page}&search=${search}`,
  });

export const getPost = id =>
  request({
    method: 'GET',
    url: `/api/posts/${id}`,
  });

export const postPost = data => request({ method: 'POST', url: '/api/posts', data });

export const putPost = data => request({ method: 'PUT', url: `/api/posts/${data.id}`, data });

export const archivePost = id => request({ method: 'DELETE', url: `/api/posts/${id}` });

export const getArchivePosts = ({ page = 1, search = '' }) =>
  request({ method: 'GET', url: `/api/posts/archive?page=${page}&search=${search}` });

export const restorePost = id => request({ method: 'PUT', url: `/api/posts/${id}/restore` });
