import React from 'react';
import Loadable from 'react-loadable';

import { AuthenticatedRoute } from 'components/Routing';

const Projects = Loadable({
  loader: () => import('./List'),
  loading: () => <div>Loading</div>,
});

const ProjectCreate = Loadable({
  loader: () => import('./Create'),
  loading: () => <div>Loading</div>,
});

const ProjectEdit = Loadable({
  loader: () => import('./Edit'),
  loading: () => <div>Loading</div>,
});

const ProjectsArchive = Loadable({
  loader: () => import('./Archive'),
  loading: () => <div>Loading</div>,
});

const ProjectsRoutes = [
  <AuthenticatedRoute key="1" exact path="/projects" component={Projects} />,
  <AuthenticatedRoute
    key="2"
    excat
    path="/projects/create"
    component={ProjectCreate}
  />,
  <AuthenticatedRoute
    key="3"
    excat
    path="/projects/:id/edit"
    component={ProjectEdit}
  />,
  <AuthenticatedRoute
    key="4"
    exact
    path="/projects/archive"
    component={ProjectsArchive}
  />,
];

export default ProjectsRoutes;
