import React from 'react';
import Loadable from 'react-loadable';

import { AuthenticatedRoute } from 'components/Routing';

const Clients = Loadable({
  loader: () => import('./List'),
  loading: () => <div>Loading</div>,
});

const ClientCreate = Loadable({
  loader: () => import('./Create'),
  loading: () => <div>Loading</div>,
});

const ClientEdit = Loadable({
  loader: () => import('./Edit'),
  loading: () => <div>Loading</div>,
});

const ClientsArchive = Loadable({
  loader: () => import('./Archive'),
  loading: () => <div>Loading</div>,
});

const ClientsRoutes = [
  <AuthenticatedRoute key="1" exact path="/clients" component={Clients} />,
  <AuthenticatedRoute
    key="2"
    excat
    path="/clients/create"
    component={ClientCreate}
  />,
  <AuthenticatedRoute
    key="3"
    excat
    path="/clients/:id/edit"
    component={ClientEdit}
  />,
  <AuthenticatedRoute
    key="4"
    excat
    path="/clients/archive"
    component={ClientsArchive}
  />,
];

export default ClientsRoutes;
