import React from 'react';
import Loadable from 'react-loadable';

import { AuthenticatedRoute } from 'components/Routing';

const Landings = Loadable({
  loader: () => import('./List'),
  loading: () => <div>Loading</div>,
});

const LandingsRoutes = [
  <AuthenticatedRoute key="1" exact path="/landings" component={Landings} />,
];

export default LandingsRoutes;
