import React from 'react';
import Loadable from 'react-loadable';

import { AuthenticatedRoute } from 'components/Routing';

const Categories = Loadable({
  loader: () => import('./List'),
  loading: () => <div>Loading</div>,
});

const CategoryCreate = Loadable({
  loader: () => import('./Create'),
  loading: () => <div>Loading</div>,
});

const CategoryEdit = Loadable({
  loader: () => import('./Edit'),
  loading: () => <div>Loading</div>,
});

const CategoriesArchive = Loadable({
  loader: () => import('./Archive'),
  loading: () => <div>Loading</div>,
});

const CategoriesRoutes = [
  <AuthenticatedRoute
    key="1"
    exact
    path="/categories"
    component={Categories}
  />,
  <AuthenticatedRoute
    key="2"
    excat
    path="/categories/create"
    component={CategoryCreate}
  />,
  <AuthenticatedRoute
    key="3"
    excat
    path="/categories/:id/edit"
    component={CategoryEdit}
  />,
  <AuthenticatedRoute
    key="4"
    excat
    path="/categories/archive"
    component={CategoriesArchive}
  />,
];

export default CategoriesRoutes;
