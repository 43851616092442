import React from 'react';
import Loadable from 'react-loadable';

import { AuthenticatedRoute } from 'components/Routing';

const Pages = Loadable({
  loader: () => import('./List'),
  loading: () => <div>Loading</div>,
});

const PageEdit = Loadable({
  loader: () => import('./Edit'),
  loading: () => <div>Loading</div>,
});

const CategoriesRoutes = [
  <AuthenticatedRoute key="1" exact path="/pages" component={Pages} />,
  <AuthenticatedRoute key="2" excat path="/pages/:id" component={PageEdit} />,
];

export default CategoriesRoutes;
