import { request } from 'utils';

export const getContents = ({
  page = 1,
  domains = [],
  artists = [],
  categories = [],
  projects = [],
  client = null,
}) =>
  request({
    method: 'GET',
    url: `/api/contents`,
    params: {
      page,
      domains,
      artists,
      categories,
      projects,
      client,
    },
  });
