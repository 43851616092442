import React from 'react';
import Loadable from 'react-loadable';

import { AuthenticatedRoute } from 'components/Routing';

const Artists = Loadable({
  loader: () => import('./List'),
  loading: () => <div>Loading</div>,
});

const ArtistCreate = Loadable({
  loader: () => import('./Create'),
  loading: () => <div>Loading</div>,
});

const ArtistEdit = Loadable({
  loader: () => import('./Edit'),
  loading: () => <div>Loading</div>,
});

const ArtistProjectsCategoryEdit = Loadable({
  loader: () => import('./ProjectsCategory'),
  loading: () => <div>Loading</div>,
});

const ArtistsArchive = Loadable({
  loader: () => import('./Archive'),
  loading: () => <div>Loading</div>,
});

const ArtistsRoutes = [
  <AuthenticatedRoute key="1" exact path="/artists" component={Artists} />,
  <AuthenticatedRoute
    key="2"
    excat
    path="/artists/create"
    component={ArtistCreate}
  />,
  <AuthenticatedRoute
    key="3"
    excat
    path="/artists/:id/edit"
    component={ArtistEdit}
  />,
  <AuthenticatedRoute
    key="4"
    excat
    path="/artists/archive"
    component={ArtistsArchive}
  />,
  <AuthenticatedRoute
    key="5"
    exact
    path="/artists/projects/:id/category/:categoryId"
    component={ArtistProjectsCategoryEdit}
  />,
];

export default ArtistsRoutes;
